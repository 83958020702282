const grays = ["#E8E8E8", "#707070", "#000000CC", "#393739"];

const brand = {
  blue: "#0B42E0",
  darkBlue: "#000676",
  lightGrey: "#f6f6f6",
  black: "#333333"
 
};  
export const colors = {
  text: brand.black,
  grayText: "#383838",
  background: "#fff",
  primary: brand.darkBlue,
  secondary: brand.blue,
  accent: "#044c8a",
  placeholder: "#707070",
  brand,
};
