export const text = {
  title: {
    fontSize: 4,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "brand.navyBlue",
    lineHeight: 1.3,
  }, 
  whiteHero: {
    fontFamily: "heading",
    fontWeight: "body",
    lineHeight: "heading",
    color:"white",
    textAlign:"center" 
  },
  heading: {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
  },
  h1: {
    color: "primary",
    fontSize: [4],
    variant: "text.heading",
    textAlign: "center",
    fontWeight: 500,
    lineHeight: 1.2,
  },
  h2: {
    color: "text",
    fontSize: [4, null, null, 4],
    fontWeight: "text",
    textAlign: "center",
  },
  // h3 needs update
  h3: {
    width: "100%",
    fontSize: 2,
    fontWeight: "bold",
    color: "white",
    textTransform: "uppercase",
    backgroundColor: "brand.tealSmallText",
    px: "10px",
    py: "5px",
    mb: "5px",
  },
  h4: {
    color: "text",
    fontSize: 2,
    lineHeight: 1.6,
    fontWeight: "heading",
    textAlign: "center",
  },
  p: {
    fontSize: 1,
    lineHeight: 1.8,
    letterSpacing: 0.5,
    color:"text", 
  },
  body: {
    fontFamily: "body",
    fontWeight: "body",
    lineHeight: "body",
  },
  disclaimer: {
    variant: "text.body",
    fontSize: 0,
  },
  productH2: {
    color: "brand.darkBlue",
    fontSize: "26px",
    letterSpacing: "0.32px",
    lineHeight: "1.5",
    mb: "2",
  },
  productParagraph: {
    fontSize: "16px",
    lineHeight: 2,
    textAlign: "left",
  },
  link: {
    fontSize: 0,
    fontWeight: 500,
    textDecoration: "none",
    color: "brand.darkBlue",
    cursor:"pointer",
    "&:hover": {
      textDecoration: "underline",
    }
  },
};
