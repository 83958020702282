module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"1st Heritage Credit","short_name":"1stheritagecredit","description":"First Heritage Credit operates branch offices in Alabama, Louisiana, Mississippi, South Carolina and Tennessee.","start_url":"/?utm_source=installed","theme_color":"#000676","display":"standalone","icon":"src/images/favicon.png","icon_options":{"purpose":"maskable badge any"},"categories":["finance","business"],"dir":"ltr","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4146383f4325555e6d7fa6bee6335316"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MQNS3MM","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Raleway:300,400,500,600,700","Montserrat:400,500,600,700,800"]}},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
